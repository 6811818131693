import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint } from '../../configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionTitle, SectionDescription, SectionText } from '../common/typography/Typography'
import ButtonLink from '../common/buttons/ButtonLink'
import ButtonContact from '../common/buttons/ButtonContact'

const ImageTextComponent = ({ ...props }) => {
  return (
    <Section reverse={props.reverse}>
      <ImageWrapper>
        <SectionTitleMobile>{props.title && <SectionTitle>{props.title}</SectionTitle>}</SectionTitleMobile>
        {props.image && <GatsbyImage image={props.image} alt={props.title} />}
      </ImageWrapper>
      <TextWrapper>
        <SectionTitleDesktop>{props.title && <SectionTitle>{props.title}</SectionTitle>}</SectionTitleDesktop>
        {props.description && <SectionDescription>{props.description}</SectionDescription>}
        {props.text01 && <SectionText>{props.text01}</SectionText>}
        {props.text02 && <SectionText>{props.text02}</SectionText>}
        <ButtonWrapper>
          {props.showButton && props.to === 'contact' && <ButtonContact buttonText={props.buttonText} />}
          {props.showButton && props.to !== 'contact' && (
            <ButtonLink external={props.external} to={props.to} buttonText={props.buttonText} />
          )}
        </ButtonWrapper>
      </TextWrapper>
    </Section>
  )
}

export default injectIntl(ImageTextComponent)

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media screen and (min-width: ${breakpoint.lg}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    column-gap: 50px;
    padding: 40px 0;
  }
`

const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media screen and (min-width: ${breakpoint.lg}) {
    width: 50%;
    max-width: 800px;
    text-align: left;
    row-gap: 20px;
  }

  p {
    margin-top: 20px;
    @media screen and (min-width: ${breakpoint.lg}) {
      margin-top: 0;
    }
  }
`

const ImageWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.lg}) {
    width: 50%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    border-radius: 30px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.lg}) {
    justify-content: flex-start;
  }
`

const SectionTitleMobile = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoint.lg}) {
    display: none;
  }
`
const SectionTitleDesktop = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoint.lg}) {
    display: flex;
  }
`
