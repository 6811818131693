import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { useStaticQuery, graphql } from 'gatsby'
import ImageTextComponent from '../../components/sections/ImageTextComponent'
import { useWindowSize } from '../../configs/getGlobalWindowSize'

const WranglerSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      wranglerXl: file(relativePath: { eq: "activities/sections/wranglerXl.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
      wranglerSm: file(relativePath: { eq: "activities/sections/wranglerSm.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <ImageTextComponent
          reverse={false}
          title={intl.formatMessage({ id: 'activities.wrangler.title' })}
          description={intl.formatMessage({ id: 'activities.wrangler.description' })}
          image={
            currentWidth > 768
              ? data.wranglerXl.childImageSharp.gatsbyImageData
              : data.wranglerSm.childImageSharp.gatsbyImageData
          }
          text01={intl.formatMessage({ id: 'activities.wrangler.text01' })}
          text02={intl.formatMessage({ id: 'activities.wrangler.text02' })}
          showButton={true}
          external={false}
          to="contact"
          buttonText={intl.formatMessage({ id: 'activities.wrangler.buttonText' })}
        />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(WranglerSection)

const Wrapper = styled.div``
