import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { GatsbyImage } from 'gatsby-plugin-image'
import InfoComponent from '../../components/sections/InfoComponent'
import { useStaticQuery, graphql } from 'gatsby'
import { breakpoint } from '../../configs/utilities'
import { useWindowSize } from '../../configs/getGlobalWindowSize'

const OurRecommendation = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allOurRecommendationJson {
        edges {
          node {
            imageXl {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
            imageSm {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)

  const OurRecommendationImages = data.allOurRecommendationJson.edges
  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <InfoComponent
          title={intl.formatMessage({ id: 'activities.ourRecommendation.title' })}
          description={intl.formatMessage({ id: 'activities.ourRecommendation.description' })}
          text={intl.formatMessage({ id: 'activities.ourRecommendation.text' })}
          showButton={true}
          external={true}
          to="https://www.avanturistickicentar.com/"
          buttonText={intl.formatMessage({ id: 'activities.ourRecommendation.buttonText' })}
        />
        <ImagesWrapper>
          {OurRecommendationImages.map((item, index) => {
            return (
              <Images key={index}>
                <GatsbyImage image={ currentWidth > 768 ? item.node.imageXl.childImageSharp.gatsbyImageData : item.node.imageSm.childImageSharp.gatsbyImageData} alt="Our Recommendation Images" />
              </Images>
            )
          })}
        </ImagesWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(OurRecommendation)

const Wrapper = styled.div``

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 12px;
  row-gap: 12px;
  margin-top: 50px;

  @media screen and (min-width: ${breakpoint.md}) {
    grid-template-columns: 2fr 1fr 2fr 1fr;
    column-gap: 18px;
    row-gap: 18px;
    margin-top: 100px;
  }
`

const Images = styled.div`
  img {
    border-radius: 8px;
  }
`
