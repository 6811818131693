import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import InfoComponent from '../../components/sections/InfoComponent'
import { useStaticQuery, graphql } from 'gatsby'
import ImageTextComponent from '../../components/sections/ImageTextComponent'
import { useWindowSize } from '../../configs/getGlobalWindowSize'

const NatureSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allNatureJson {
        edges {
          node {
            reverse
            title
            text
            imageXl {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
            imageSm {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)
  
  const natureData = data.allNatureJson.edges
  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <InfoComponent
          title={intl.formatMessage({ id: 'activities.nature.title' })}
          description={intl.formatMessage({ id: 'activities.nature.description' })}
        />
        {natureData.map((item, index) => {
          return (
            <ImageTextComponent
              key={index}
              reverse={item.node.reverse}
              title={intl.formatMessage({ id: item.node.title })}
              image={currentWidth > 768 ? item.node.imageXl.childImageSharp.gatsbyImageData : item.node.imageSm.childImageSharp.gatsbyImageData}
              text01={intl.formatMessage({ id: item.node.text })}
            />
          )
        })}
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(NatureSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
