import React from 'react'
import styled from 'styled-components'
import HeroSection from '../sections/activities/HeroSection'
import WranglerSection from '../sections/activities/WranglerSection'
import HorsesSection from '../sections/activities/HorsesSection'
import NatureSection from '../sections/activities/NatureSection'
import AdventureCenter from '../sections/activities/AdventureCenter'
import OurRecommendation from '../sections/activities/OurRecommendation'
import Layout from '../components/layout/Layout'

const Activities = () => {
  return (
    <Layout pageName="activities">
      <Wrapper>
        <HeroSection />
        <WranglerSection />
        <HorsesSection />
        <NatureSection />
        <AdventureCenter />
        <OurRecommendation />
      </Wrapper>
    </Layout>
  )
}

export default Activities

const Wrapper = styled.div``