import React from 'react'
import styled from 'styled-components'
import divider from '../../assets/activities/sections/divider.svg'
import { InfoTitle, SectionDescription, SectionText } from '../common/typography/Typography'
import ButtonLink from '../common/buttons/ButtonLink'

const InfoComponent = ({ buttonText, external, to, ...props }) => {
  return (
    <Wrapper>
      <InfoTitle>{props.title}</InfoTitle>
      <img src={divider} alt="divider" />
      <SectionDescription>{props.description}</SectionDescription>
      <SectionText>{props.text}</SectionText>
      <ButtonWrapper>{props.showButton && <ButtonLink external={external} to={to} buttonText={buttonText} />}</ButtonWrapper>
    </Wrapper>
  )
}

export default InfoComponent

const Wrapper = styled.div`
width: 90%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
   
  img {
    width: 200px;
    margin: 20px 0 50px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 30px;
`
