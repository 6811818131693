import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import InfoComponent from '../../components/sections/InfoComponent'
import { useStaticQuery, graphql } from 'gatsby'
import ImageTextComponent from '../../components/sections/ImageTextComponent'
import { useWindowSize } from '../../configs/getGlobalWindowSize'

const AdventureCenter = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allAdventureCenterJson {
        edges {
          node {
            reverse
            title
            text01
            text02
            showButton
            button {
              external
              path
              buttonText
            }
            imageXl {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
            imageSm {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)
  
  const adventureCenterData = data.allAdventureCenterJson.edges
  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <InfoComponent
          title={intl.formatMessage({ id: 'activities.adventureCenter.title' })}
          description={intl.formatMessage({ id: 'activities.adventureCenter.text' })}
        />
        {adventureCenterData.map((item, index) => {
          return (
            <ImageTextComponent
              key={index}
              reverse={item.node.reverse}
              title={intl.formatMessage({ id: item.node.title })}
              image={currentWidth > 768 ? item.node.imageXl.childImageSharp.gatsbyImageData : item.node.imageSm.childImageSharp.gatsbyImageData}
              text01={intl.formatMessage({ id: item.node.text01 })}
              text02={item.node.text02 !== null ? intl.formatMessage({ id: item.node.text02 }) : null}
              showButton={item.node.showButton}
              external={item.node.button !== null ? item.node.button.external : null}
              to={item.node.button !== null ? item.node.button.path : null}
              buttonText={item.node.button !== null ? intl.formatMessage({ id: item.node.button.buttonText }) : null} 
            />
          )
        })}
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(AdventureCenter)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
