import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import InfoComponent from '../../components/sections/InfoComponent'
import { breakpoint } from '../../configs/utilities'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useWindowSize } from '../../configs/getGlobalWindowSize'
import { SectionText } from '../../components/common/typography/Typography'
import VideoComponent from '../../components/sections/VideoComponent'
import videoBackgroundSm from '../../assets/activities/sections/videoBackgroundSm.webp'
import videoBackgroundMd from '../../assets/activities/sections/videoBackgroundMd.webp'
import videoBackgroundXl from '../../assets/activities/sections/videoBackgroundXl.webp'

const HorsesSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      horse01Xl: file(relativePath: { eq: "activities/sections/horse01Xl.webp" }) {
        childImageSharp {
          gatsbyImageData( width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [768, 1380])
        }
      }
      horse01Sm: file(relativePath: { eq: "activities/sections/horse01Sm.webp" }) {
        childImageSharp {
          gatsbyImageData( width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      horse02Xl: file(relativePath: { eq: "activities/sections/horse02Xl.webp" }) {
        childImageSharp {
            gatsbyImageData( width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [768, 1380])
        }
      }
      horse02Sm: file(relativePath: { eq: "activities/sections/horse02Sm.webp" }) {
        childImageSharp {
          gatsbyImageData( width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <InfoComponent
          title={intl.formatMessage({ id: 'activities.horses.title' })}
          text={intl.formatMessage({ id: 'activities.horses.text01' })}
        />
        <ImageWrapper>
          <GatsbyImage image={currentWidth > 768 ? data.horse01Xl.childImageSharp.gatsbyImageData : data.horse01Sm.childImageSharp.gatsbyImageData} alt="Horse Image" />
          <GatsbyImage image={currentWidth > 768 ? data.horse02Xl.childImageSharp.gatsbyImageData : data.horse02Sm.childImageSharp.gatsbyImageData} alt="Horse Image" />
        </ImageWrapper>
        <TextWrapper>
          <SectionText>{intl.formatMessage({ id: 'activities.horses.text02' })}</SectionText>
          <SectionText>{intl.formatMessage({ id: 'activities.horses.text03' })}</SectionText>
        </TextWrapper>
        <VideoComponent
        title={intl.formatMessage({ id: 'activities.horses.videoText' })}
        videoId="773310927"
        heroSm={videoBackgroundSm}
        heroMd={videoBackgroundMd}
        heroXl={videoBackgroundXl}
      />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(HorsesSection)

const Wrapper = styled.div``

const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 30px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
     margin: 70px 0;
  }

  img {
   border-radius: 18px;
  }
`

const TextWrapper = styled.div`
  width: 90%;
  max-width: 1000px;
  margin: 0 auto 50px;
  text-align: center;
  
  @media screen and (min-width: ${breakpoint.xxl}) {
    margin: 0 auto 70px;
  }
`
